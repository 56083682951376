import axios from "axios";
import {useMemo, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import ServerHandler from "../../objects/ServerHandler";

type UserAuthObject = {
    id : string;
    charactername : string;
}

type ClientListObject = {
    id : string;
    headline : string;
    time : string;
    maintext : string;
    sender : string;
    receiver : string;
}

export function EasyMail() {
    const [userobject, setUserObject] = useState<UserAuthObject>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [clientList, setClientList] = useState<ClientListObject[]>([]);

    function logout() {
        setUserObject(undefined);
    }

    function pullClientsFromServer() {
        if (!userobject) return;
        axios.post(ServerHandler.host()+"/easymail/maillist",
            {
                user_id: userobject.id
            }).then(response => {

            let items: ClientListObject[] = [];
            for (let item of response.data.emails) {
                items.push({
                    id: item.id,
                    headline : item.headline,
                    time : item.time,
                    maintext : item.maintext,
                    sender: item.sender,
                    receiver : item.receiver
                });
            }

            setClientList(items);

            console.log("Client data : "+items);
        }).catch(function(error) {
            console.log(error);
        });
    }

    function loginQuery(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };

        axios.post(ServerHandler.host()+"/easymail/login",
            {
                u: target.email.value,
                p: target.password.value
            }).then(response => {

            if (response.data.error) {
                setErrorMessage(response.data.error);
                return;
            }

            if (response.data.id) {
                let userAuth = ({
                    id: response.data.id,
                    charactername : response.data.charactername
                });
                setUserObject(userAuth);
                pullClientsFromServer();
            }
        }).catch(function(error) {
            console.log(error);
        });
    }


    const cols = useMemo<ColumnDef<ClientListObject>[]>(
        () => [
            {
                header: 'Headline',
                cell: (row) => row.renderValue(),
                accessorKey: 'charactername',
                filterable : true
            },
            {
                header: 'Status',
                cell: (row) => row.renderValue(),
                accessorKey: 'currentstatus',
                filterable : true
            },
        ],
        []
    );

    return (<>

        <h1>EasyMail</h1>
        {userobject && (
            <>
                <h3>Hello {userobject.charactername}</h3>

                <h4>Inbox:</h4>
                {clientList.length > 0 &&
                        <table>
                            {clientList.map((email) =>
                                ( <><tr><th>{email.headline}</th></tr>
                                    <tr><td>{email.sender} =&gt; {email.receiver}</td></tr>
                                    <tr><td>{email.time}</td></tr>
                                    <tr><td>{email.maintext}</td></tr></>))
                            }
                        </table>
                }

                <button onClick={() => pullClientsFromServer()}>Refresh clients</button>

                <div>
                    <button onClick={() => logout()}>Logout</button>
                </div>
            </>
        )}
        {!userobject && (<>
                <form id="login" onSubmit={loginQuery}>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input name="email" type="text" placeholder="you@easymail.com"></input>
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input name="password" type="password"></input>
                    </div>
                    {errorMessage && (
                        <><div className="error">{errorMessage}</div></>
                    )}
                    <div>
                        <input type="submit" value="Login"/>
                    </div>
                </form>
            </>
        )}
    </>);
}
