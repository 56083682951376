import React, {useState} from "react";
import linksData from "../../objects/LinkData";
import SearchLink from "../../objects/SearchLink";
import {useLocation} from "react-router-dom";

export function SiteAltaVista() {
    const categories = Array.from(new Set(linksData.flatMap((link) => link.categories)));
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const location = useLocation();

    const handleCategoryClick = (category: string) => {
        setActiveCategory(category);
        setSearchQuery(''); // Clear search query when a category is clicked
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value.toLowerCase();
        setActiveCategory(null); // Clear active category when performing a search
        setSearchQuery(query);
    };

    React.useEffect(() => {
        const link = document.createElement("link");
        link.href = "/sites/omnivista/omnivista.css"; // Adjust the path as needed
        link.rel = "stylesheet";
        document.head.appendChild(link);

        return () => {
            // Cleanup function to remove the added <link> element
            document.head.removeChild(link);
        };
    }, [location]);

    const filteredLinks = linksData.filter(
        (link) =>
            (activeCategory && link.categories.includes(activeCategory)) ||
            (searchQuery &&
                (link.title.toLowerCase().includes(searchQuery) ||
                    link.keywords.some((keyword) => keyword.includes(searchQuery)))) ||
            (searchQuery &&
                link.categories.some((category) => category.toLowerCase().includes(searchQuery)))
    );

    const toCamelCase = (str: string): string => {
        return str
            .toLowerCase()
            .replace(/(^|\s)(\w)/g, (match, group1, group2) => group1 + group2.toUpperCase());
    };

    return (
        <>
            <div className="text-center">
                <img src="/sites/omnivista/vistalogo.png" className="omnilogo"/>
            </div>
        <div className="container py-5">
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control searchbox"
                    placeholder="Search for links or categories..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    inputMode="search"
                />
            </div>
            <div className="justify-content-center mb-4">
                {categories.sort().map((category) => (
                    <div
                        key={category}
                        onClick={() => handleCategoryClick(category)}
                        className={`btn btn-outline-primary btn-sm mx-1 ${
                            activeCategory === category ? 'active' : ''
                        }`}
                    >
                        {toCamelCase(category)}
                    </div>
                ))}
            </div>
            <div >
                {filteredLinks.map((link) => (
                    <div className="col-12">
                        <SearchLink
                            title={link.title}
                            url={link.url}
                            icon={link.icon}
                            description={link.description}
                        />
                    </div>
                ))}
            </div>
        </div></>
    );
}
