import ServerHandler from "../objects/ServerHandler";
import axios from "axios";
import {useMemo, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {Table} from "../interfaces/Table";

type CreditLookupObject = {
    name: string;
    creditscore : number;
}

export function CreditScoreLookup() {

    const [result, setResult] = useState<CreditLookupObject>();

    function loadQuery(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            querystring: { value: string };
        };

        axios.post(ServerHandler.host()+"/lookup/creditscore",
            {
                query: target.querystring.value
            }).then(response => {
            if (response.data.person) {
                let item = ({
                    name: response.data.person.charactername,
                    creditscore : response.data.person.creditscore
                });
                setResult(item);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }


    return (
        <>
            <h1>Credit score lookup service</h1>

            <form className="my-3" onSubmit={loadQuery}>
                <div className="input-group">
                    <input
                        type="text"
                        name="querystring"
                        className="form-control"
                        placeholder="EXACT legal name of the person you want to check..."
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                            Search
                        </button>
                    </div>
                </div>
            </form>


            {result &&
                <div className="card mt-3">
                    <div className="card-header">
                        <h1>{result.name} Results</h1>
                        Credit rating : {result.creditscore}/100
                    </div>
                </div>
            }
        </>
    );
}
