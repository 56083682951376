
var ServerHandler = (function() {
    var host = function() {
        return "https://resolverserver.com/api/verse";
        if (window.location.hostname.startsWith("local")) return "http://crime.test/api/verse";
        return "https://resolverserver.com/api/verse";
    }

    return {
        host : host
    }; // infers [boolean, typeof load] instead of (boolean | typeof load)[]

})();

export default ServerHandler;