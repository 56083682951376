import React from 'react';
import {Link} from "react-router-dom";

const JoseiPracticalInformation: React.FC = () => {
    return (
        <div className="container mt-4">
            <h2>Practical Information</h2>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Ticket Information</h4>
                </div>
                <div className="card-body">
                    <p>Tickets for JoseiCon can be purchased online through our website or at the door during the event.</p>
                    <p>General Admission: $30</p>
                    <p>VIP Pass: $60 (Includes early access and exclusive merchandise)</p>
                    <p>Children under 12 years old enter for free with a paying adult.</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Location and Address</h4>
                </div>
                <div className="card-body">
                    <p>JoseiCon will be held at the Oslo Convention Center:</p>
                    <p>12 Galvang Avenue</p>
                    <p>Oslo, Norway</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Transportation</h4>
                </div>
                <div className="card-body">
                    <p>Public transportation options are available, including buses and trains that stop near the convention center.</p>
                    <p>For those driving, there is parking available on-site for a fee.</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Accommodation</h4>
                </div>
                <div className="card-body">
                    <p>Several hotels are located within walking distance of the convention center, offering special rates for JoseiCon attendees. Be sure to mention the event when booking your stay.</p>
                    <p>It is NOT possible to sleep at the convention grounds.</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Food and Refreshments</h4>
                </div>
                <div className="card-body">
                    <p>Food and beverage vendors will be available on-site, offering a variety of options to suit different dietary preferences.</p>
                    <p>You are also welcome to bring your own snacks and non-alcoholic beverages.</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Accessibility</h4>
                </div>
                <div className="card-body">
                    <p>The convention center is wheelchair accessible, and there are designated rest areas for attendees who may need a break.</p>
                    <p>If you have specific accessibility needs, please contact us in advance so we can make necessary arrangements.</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Code of Conduct</h4>
                </div>
                <div className="card-body">
                    <p>Please read our seperate page about <Link to="/joseicon.no/rules">rules of conduct</Link>.</p>
                </div>
            </div>

            {/* You can add more practical information sections as needed */}
        </div>
    );
}

export default JoseiPracticalInformation;
