import React from 'react';
import {Routes, Route} from "react-router-dom";
import {SiteAltaVista} from "./sites/indexes/sitealtavista";
import linksData from "./objects/LinkData";
import AddressBar from "./objects/AddressBar";
import {NotFound} from "./sites/errors/notfound";
import {Conventionpage} from "./sites/cosplay/conventionpage";


function App() {
  return (
      <>
          <AddressBar />
    <div className="App container container-fluid">
<div className="row">

    <div className="col-md-12">
        <Routes>
            <Route index element={<SiteAltaVista />}/>
            {linksData.map((link) => (
                <Route path={link.url} element={link.content}></Route>
            ))}
            <Route path='joseicon.no/*' element={<Conventionpage />}/>
            <Route path='*' element={<NotFound />}/>
        </Routes>
    </div>
</div>

    </div>
      </>
  );
}

export default App;
