import ServerHandler from "../objects/ServerHandler";
import axios from "axios";
import {useMemo, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {Table} from "../interfaces/Table";

type UserAuthObject = {
    id : string;
    charactername : string;
}

type ClientListObject = {
    id : string;
    charactername : string;
    currentstatus : string;
}

export function DoctorNet() {
    const [userobject, setUserObject] = useState<UserAuthObject>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [clientList, setClientList] = useState<ClientListObject[]>([]);

    function logout() {
        setUserObject(undefined);
    }

    function pullClientsFromServer() {
        if (!userobject) return;
        axios.post(ServerHandler.host()+"/doctornet/clientlist",
            {
                user_id: userobject.id
            }).then(response => {

            let items: ClientListObject[] = [];
            for (let item of response.data.people) {
                items.push({
                    id: item.id,
                    charactername : item.charactername,
                    currentstatus : item.medicalstatus
                });
            }

            setClientList(items);

            console.log("Client data : "+items);
        }).catch(function(error) {
            console.log(error);
        });
    }

    function loginQuery(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };

        axios.post(ServerHandler.host()+"/doctornet/login",
            {
                u: target.email.value,
                p: target.password.value
            }).then(response => {

            if (response.data.error) {
                setErrorMessage(response.data.error);
                return;
            }

            if (response.data.id) {
                let userAuth = ({
                    id: response.data.id,
                    charactername : response.data.charactername
                });
                setUserObject(userAuth);
            }
        }).catch(function(error) {
            console.log(error);
        });
    }


    const cols = useMemo<ColumnDef<ClientListObject>[]>(
        () => [
            {
                header: 'Name',
                cell: (row) => row.renderValue(),
                accessorKey: 'charactername',
                filterable : true
            },
            {
                header: 'Status',
                cell: (row) => row.renderValue(),
                accessorKey: 'currentstatus',
                filterable : true
            },
        ],
        []
    );

    return (<>

        <h1>DoctorNet</h1>
        {userobject && (
            <>
                <h3>Hello {userobject.charactername}</h3>

<h4>Patient list:</h4>
                {clientList.length > 0 &&
                    <div className="card mt-3">
                        <div className="card-header">
                            <h1>{clientList.length} Active clients</h1>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <Table data={clientList} columns={cols} />
                            </div>
                        </div>
                    </div>
                }

                <button onClick={() => pullClientsFromServer()}>Refresh clients</button>

                <div>
                    <button onClick={() => logout()}>Logout</button>
                    </div>
            </>
        )}
        {!userobject && (<>
                <form id="login" onSubmit={loginQuery}>
                    <div>
                        <label htmlFor="email">Phonenumber:</label>
                        <input name="email" type="text" placeholder="name@medicalresidence.com"></input>
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input name="password" type="password"></input>
                    </div>
                    {errorMessage && (
                        <><div className="error">{errorMessage}</div></>
                    )}
                    <div>
                        <input type="submit" value="Login"/>
                    </div>
                </form>
            </>
        )}
    </>);
}
