import ServerHandler from "../objects/ServerHandler";
import axios from "axios";
import {useState} from "react";

type UserAuthObject = {
    id : string;
    charactername : string;
}

export function PhoneCompany() {
    const [userobject, setUserObject] = useState<UserAuthObject>();
    const [errorMessage, setErrorMessage] = useState<string>();

    function logout() {
        setUserObject(undefined);
    }

    function loginQuery(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            phonenumber: { value: string };
            password: { value: string };
        };

        axios.post(ServerHandler.host()+"/phonecompany/login",
            {
                u: target.phonenumber.value,
                p: target.password.value
            }).then(response => {

                if (response.data.error) {
                    setErrorMessage(response.data.error);
                    return;
                }

                if (response.data.id) {
                    let userAuth = ({
                        id: response.data.id,
                        charactername : response.data.charactername
                    });
                    setUserObject(userAuth);
                }

                /*
                if (response.data.person) {
                let item = ({
                    name: response.data.person.charactername,
                    creditscore : response.data.person.creditscore
                });
                setResult(item);
                */
        }).catch(function(error) {
            console.log(error);
        });
    }

    return (<>

        <h1>Phone company</h1>
    {userobject && (
        <>
        <h3>Hello {userobject.charactername}</h3>
            <button onClick={() => logout()}>Logout</button>
        </>
    )}
        {!userobject && (<>
        <form id="login" onSubmit={loginQuery}>
            <div>
            <label htmlFor="phonenumber">Phonenumber:</label>
            <input name="phonenumber" type="text" placeholder="+1 494"></input>
            </div>
            <div>
            <label htmlFor="password">Password:</label>
            <input name="password" type="password"></input>
            </div>
            {errorMessage && (
                <><div className="error">{errorMessage}</div></>
            )}
            <div>
                <input type="submit" value="Login"/>
            </div>
        </form>
        </>
        )}
    </>);
}
