import React from 'react';

const JoseiConventionRules: React.FC = () => {
    return (
        <div className="container mt-4">
            <h2>JoseiCon Convention Rules</h2>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>General Guidelines</h4>
                </div>
                <div className="card-body">
                    <ul>
                        <li>Respect and inclusivity are paramount. Treat all attendees, staff, and participants with kindness and consideration.</li>
                        <li>Harassment, bullying, or non-consensual behavior will not be tolerated.</li>
                        <li>Follow the instructions of event staff and security personnel at all times.</li>
                        <li>Engage in a positive and welcoming manner. Be mindful of your language and actions.</li>
                        <li>Do not engage in any disruptive or unsafe behavior that may interfere with the enjoyment of others.</li>
                    </ul>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Cosplay and Props</h4>
                </div>
                <div className="card-body">
                    <ul>
                        <li>All cosplay weapons and props must be inspected and approved at the designated registration area before entering the convention.</li>
                        <li>Avoid sharp or pointed edges, and refrain from using real firearms or realistic replicas.</li>
                        <li>Projectile weapons and any item deemed potentially harmful are strictly prohibited.</li>
                        <li>Costumes and props should not hinder mobility or cause safety hazards.</li>
                        <li>Cosplay should conform to appropriate levels of decency and should not be overly revealing.</li>
                    </ul>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Harassment Policy</h4>
                </div>
                <div className="card-body">
                    <p>JoseiCon maintains a zero-tolerance policy for any form of harassment, including but not limited to:</p>
                    <ul>
                        <li>Verbal, physical, or written abuse</li>
                        <li>Unwanted advances or attention</li>
                        <li>Intimidation or stalking</li>
                        <li>Offensive comments or slurs</li>
                    </ul>
                    <p>If you experience or witness any form of harassment, please report it to event staff immediately.</p>
                </div>
            </div>

            {/* Add more sections and rules as needed */}
        </div>
    );
}

export default JoseiConventionRules;
