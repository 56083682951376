import React, {useMemo, useState} from "react";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import JoseiConIntro from "./joseicon/Intro";
import JoseiEventSchedule from "./joseicon/schedule";
import JoseiConventionRules from "./joseicon/rules";
import JoseiPracticalInformation from "./joseicon/practical";


export function Conventionpage() {
    const location = useLocation();

    React.useEffect(() => {
        const link = document.createElement("link");
        link.href = "/sites/josei/josei.css"; // Adjust the path as needed
        link.rel = "stylesheet";
        document.head.appendChild(link);

        return () => {
            // Cleanup function to remove the added <link> element
            document.head.removeChild(link);
        };
    }, [location]);

    return (
        <>
            <div className="text-center">
          <img src="/sites/josei/josei_logo_300.png" className="joseilogo"/>
<br/>
                <Link to="/joseicon.no" className="btn btnhead btn-lg btn-info">Front</Link>
                <Link to="/joseicon.no/events" className="btn btnhead btn-lg btn-info">Events</Link>
                <Link to="/joseicon.no/practical" className="btn btnhead btn-lg btn-info">Practicals</Link>
                <Link to="/joseicon.no/rules" className="btn btnhead btn-lg btn-info">Rules</Link>
            </div>

            <Routes>
                <Route path='events' element={<JoseiEventSchedule />}/>
                <Route path='rules' element={<JoseiConventionRules />}/>
                <Route path='practical' element={<JoseiPracticalInformation />}/>
                <Route index element={<JoseiConIntro />}/>
            </Routes>
        </>
    );
}
