// AddressBar.tsx
import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AddressBar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [address, setAddress] = React.useState('');
    const [gameClient, setGameClient] = useState<boolean>();

    // Set the current route to the address state when the component mounts
    React.useEffect(() => {
        setAddress(formatAddress(location.pathname));
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has("gameclient")) setGameClient(true);
    }, [location.pathname]);

    
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleGoClick = () => {
        if (address.startsWith('/')) {
            // If the address starts with '/', navigate to the route directly
            navigate(address, { replace: true });
        } else {
            navigate(address.replace("https://","/"), { replace: true });
            // If the address does not start with '/', ignore the Go button click
            // Alternatively, you can show an error message or handle as needed.
        }
    };


    function handleForm(e: React.SyntheticEvent) {
        e.preventDefault();
        handleGoClick();
    }

    const handleHomeClick = () => {
        navigate('/omnivista.com');
    };

    const formatAddress = (path: string) => {
        // Remove the leading '/' from the pathname
        return "https:/"+ path;
    };
    

    return (
        <div className={(gameClient ? 'hidden' : 'browser')+' address-bar' }>
            {window.navigator.userAgent}
            <button onClick={() => navigate(-1)} className="d-none d-md-block">&#8592;</button>
            <button onClick={() => navigate(1)} className="d-none d-md-block">&#8594;</button>
            <button onClick={handleHomeClick}>Home</button>
            <form onSubmit={handleForm}>
                <input className="address-text" type="text" value={address} inputMode="search" onChange={handleAddressChange} />
            </form>
            <button onClick={handleGoClick} className="d-none d-md-block">Go</button>
        </div>
    );
};

export default AddressBar;
