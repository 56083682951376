import React from 'react';
import {useState} from "react";
import ServerHandler from "../../objects/ServerHandler";
import axios from "axios";
import {useLocation} from "react-router-dom";

export function Myface() {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pageContent, setPageContent] = useState<string>('');

    const location = useLocation();

    React.useEffect(() => {
        const link = document.createElement("link");
        link.href = "/sites/myface/style.css"; // Adjust the path as needed
        link.rel = "stylesheet";
        document.head.appendChild(link);

        return () => {
            // Cleanup function to remove the added <link> element
            document.head.removeChild(link);
        };
    }, [location]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = (event as React.ChangeEvent<HTMLInputElement>).target.value;
        facesearch(inputValue.toLowerCase());
    };

    const handleFaceSearch = (name: string) => {
        facesearch(name.toLowerCase());

    };

    const handleActionClick = (action: string | null) => {
        if (action != null) handleFaceSearch(action);
    };

    function facesearch(query : string) {

        axios.post(ServerHandler.host()+"/myface",
            {
                q: query
            }).then(response => {
            setPageContent(response.data);

        }).catch(function(error) {
            console.log(error);
        });
    }

    function loadQuery(e: React.SyntheticEvent) {
        e.preventDefault();

    }

    return (
        <div className="facebook">
        <div className="facebook-header">
            <img src="/sites/myface/header.png" alt="Facebook Header" />
            <div className="search-bar-container">
                <div className="container">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search for people..."
                            onBlur={handleSearchChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearchChange(e);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
            <div
                className="myface-content"
                onClick={(e) => {
                    const parentWithDataAction = (e.target as HTMLElement).closest('[data-action]');
                    if (parentWithDataAction) {
                        const action = parentWithDataAction.getAttribute('data-action');
                        handleActionClick(action);
                    }
                }}
                dangerouslySetInnerHTML={{ __html: pageContent }}
            ></div>
        </div>
    );
}
