import {useState} from "react";
import linksData from "../../objects/LinkData";
import SearchLink from "../../objects/SearchLink";

export function NotFound() {

    return (
        <div className="container py-5">
            <h1>404</h1>

            The page you are looking for could not be found.
        </div>
    );
}
