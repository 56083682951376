import { useEffect, useState } from "react";

export function Decryptor() {
    const [guesses, setGuesses] = useState<string[]>([]);
    const [revealedWords, setRevealedWords] = useState<string[]>([]);

    const basicWords = ["the", "if", "in", "to", "is", "a", "an"];

    const parseRevealedText = (text: string) => {
        const wordsAndPunctuations = text.split(/(\s+|[\.,!;?])/);
        const cleanedWordsAndPunctuations = wordsAndPunctuations.filter((word) => !!word.trim());
        setRevealedWords(cleanedWordsAndPunctuations);
    };

    useEffect(() => {
        parseRevealedText("This is a secret message, can you guess what it says?");
    }, []);

    const handleGuess = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputGuess = event.target.value.toLowerCase().trim();
        if (inputGuess && !guesses.includes(inputGuess)) {
            const isWordFound = revealedWords.some((word) => word.toLowerCase() === inputGuess);
            if (isWordFound) {
                event.target.value = ""; // Clear the input box when the word is found
            } else {
                event.target.style.color = "red"; // Turn the input box text red when the word is not found
            }
            setGuesses([...guesses, inputGuess]);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleGuess({
                target: event.target as HTMLInputElement,
            } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const isWordUndedacted = (word: string) => {
        return basicWords.includes(word.toLowerCase()) || word === "," || word === "?" || word === ".";
    };

    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-md-8">
                    <h1>Decrypt tool</h1>

                    <input
                        name="guess"
                        type="text"
                        onBlur={(e) => (e.target.style.color = "")} // Reset text color when input loses focus
                        onKeyPress={handleKeyPress}
                        onChange={(e) => (e.target.style.color = "")} // Reset text color on every change
                    />

                    <p id="encrypted_message" className="text-monospace">
                        {revealedWords.map((word, index) => (
                            <span key={index}>
                {isWordUndedacted(word) || guesses.includes(word.toLowerCase()) ? (
                    `${word} `
                ) : (
                    <span style={{ background: "#ccc" }}>{Array(word.length).fill("█").join("")} </span>
                )}
              </span>
                        ))}
                    </p>
                </div>
                <div className="col-md-4">
                    <div>
                        <h2>Guesses:</h2>
                        <ul>
                            {guesses.map((guess, index) => (
                                <li key={index}>{guess}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
