import React from "react";

const JoseiConIntro: React.FC = () => {
    return (
        <div className="container mt-4">
            <div className="jumbotron">
                <h3 className="display-4">Experience the Magic of Fandom</h3>
                <p className="lead">Join us for a spectacular celebration of anime, manga, movies, and everything fandom-related!</p>
                <hr className="my-4" />
                <p>Explore a world of creativity, connect with fellow enthusiasts, and immerse yourself in a weekend of fun and excitement.</p>
                <p className="lead">
                    <a className="btn btn-primary btn-lg" href="/joseicon.no/events" role="button">View Schedule</a>
                </p>
            </div>
            <h2 className="text-center">Welcome to JoseiCon Oslo: Where Imagination Comes to Life!</h2>

            <h3 className="mt-4">What is JoseiCon?</h3>
            <p>JoseiCon is not just an event – it's an experience that brings together fans, enthusiasts, and creators from across the realms of anime, manga, gaming, and beyond. Whether you're an avid cosplayer, an anime aficionado, a dedicated gamer, or simply someone who appreciates the magic of pop culture, JoseiCon offers something for everyone.</p>

            <h3>Highlights of JoseiCon:</h3>
            <ul>
                <li><strong>Cosplay Extravaganza:</strong> Unleash your inner hero, villain, or anything in between! Our cosplay showcase is the perfect platform to let your creativity shine. Don your finest costumes, transform into your favorite characters, and strut your stuff on our grand stage.</li>
                <li><strong>Guests and Panels:</strong> Rub shoulders with industry professionals, artists, and influencers. Engage in insightful panels, workshops, and discussions that dive deep into the worlds you love.</li>
                <li><strong>Gaming Galore:</strong> Level up your gaming experience with a plethora of video games, tabletop games, and interactive experiences. Challenge friends, make new ones, and become a part of the gaming community like never before.</li>
                <li><strong>Artist Alley and Merchandise:</strong> Explore our vibrant Artist Alley, where talented artists and creators showcase their unique works. Discover one-of-a-kind art pieces, merchandise, and collectibles that you won't find anywhere else.</li>
                <li><strong>Screenings and Premieres:</strong> Immerse yourself in the latest anime releases, classic favorites, and exclusive premieres. Experience the magic of the big screen in the company of fellow fans.</li>
            </ul>

            <h3>Our Commitment to Your Safety:</h3>
            <p>At JoseiCon, your safety and well-being are our top priorities. We have implemented comprehensive security measures and protocols to ensure a secure and enjoyable environment for all attendees. Our staff is dedicated to upholding these standards and creating a safe space where everyone can fully enjoy the convention experience.</p>

            <p>Join Us at JoseiCon:</p>
            <p>Mark your calendars and prepare for an adventure like no other. Whether you're an enthusiastic cosplayer, an avid gamer, an anime aficionado, or simply someone who loves to celebrate pop culture, JoseiCon welcomes you with open arms.</p>
            <p>Stay tuned for updates, announcements, and exciting reveals as we count down the days to the ultimate celebration of imagination and camaraderie. Together, let's make JoseiCon Oslo an event to remember!</p>

            <p>See you at the convention!</p>
        </div>
    );
}

export default JoseiConIntro;
