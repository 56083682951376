import React from 'react';

const JoseiEventSchedule: React.FC = () => {
    return (
        <div className="container mt-4">
            <h2>JoseiCon Oslo Event Schedule: August 11th - 13th</h2>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Friday, August 11th: Day 1 - Cosplay Kickoff</h4>
                </div>
                <div className="card-body">
                    <h5>Main Stage:</h5>
                    <ul>
                        <li>10:00 AM - Opening Ceremony: Welcome to JoseiCon!</li>
                        <li>11:00 AM - Cosplay Panel: Unleash Your Inner Hero</li>
                        <li>1:00 PM - Guest Spotlight: Meet the Creators</li>
                    </ul>

                    <h5>Workshop Rooms:</h5>
                    <ul>
                        <li>2:30 PM - Cosplay Crafting 101</li>
                        <li>4:00 PM - Makeup Magic: Transforming into Your Favorite Character</li>
                    </ul>

                    <h5>Gaming Arena:</h5>
                    <ul>
                        <li>11:00 AM - eSports Tournament: Battle Royale</li>
                        <li>3:00 PM - Retro Gaming Marathon: Nostalgia Unleashed</li>
                    </ul>

                    <h5>Artist Alley:</h5>
                    <p>All Day - Artist Exhibits, Art Demos, and Artwork Sales</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Saturday, August 12th: Day 2 - Cosplay Spectacular & Movie Prop Auction</h4>
                </div>
                <div className="card-body">
                    <h5>Main Stage:</h5>
                    <ul>
                        <li>10:00 AM - Cosplay Showcase Registration</li>
                        <li>11:30 AM - Cosplay Showcase: Heroes & Villains Unite</li>
                        <li>1:00 PM - Guest Panel: Behind the Scenes of Your Favorite Anime</li>
                        <li>3:00 PM - Movie Prop Auction: Icons of the Silver Screen</li>
                    </ul>

                    <h5>Workshop Rooms:</h5>
                    <ul>
                        <li>12:00 PM - Wig Styling Techniques</li>
                        <li>2:00 PM - Foam Armor Crafting Workshop</li>
                    </ul>

                    <h5>Gaming Arena:</h5>
                    <ul>
                        <li>10:30 AM - Cosplay Chess: Live-Action Strategy Game</li>
                        <li>2:30 PM - Indie Game Developers Showcase</li>
                    </ul>

                    <h5>Artist Alley:</h5>
                    <p>All Day - Artist Exhibits, Art Demos, and Artwork Sales</p>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-header">
                    <h4>Sunday, August 13th: Day 3 - Farewell and Finale</h4>
                </div>
                <div className="card-body">
                    <h5>Main Stage:</h5>
                    <ul>
                        <li>10:00 AM - Morning Yoga: Energize for the Last Day</li>
                        <li>11:00 AM - Guest Panel: Exploring Cultural Influences in Anime</li>
                        <li>1:00 PM - Closing Ceremony: Memories and Thanks</li>
                    </ul>

                    <h5>Workshop Rooms:</h5>
                    <ul>
                        <li>12:30 PM - Voice Acting Workshop: Bring Characters to Life</li>
                        <li>3:00 PM - Manga Drawing Techniques</li>
                    </ul>

                    <h5>Gaming Arena:</h5>
                    <ul>
                        <li>10:30 AM - Board Game Bonanza: Tabletop Adventures</li>
                        <li>2:00 PM - Cosplay Lip Sync Battle: Show Your Performance Skills</li>
                    </ul>

                    <h5>Artist Alley:</h5>
                    <p>All Day - Artist Exhibits, Art Demos, and Artwork Sales</p>
                </div>
            </div>
        </div>
    );
}

export default JoseiEventSchedule;
