// Link.tsx
import React from 'react';
import {Link} from "react-router-dom";

interface SearchLinkProps {
    title: string;
    url: string;
    icon: string;
    description: string;
}

const SearchLink: React.FC<SearchLinkProps> = ({ title, url, icon, description }) => {
    url = "/"+url;
    return (
        <div className="link">
            <Link to={url}>
                <img src={icon} className="linkListIcon" alt={title} />
            </Link>
            <div className="description">
                <Link to={url}>
                <h3>{title}</h3>
                </Link>
                <i className="showlink">https:/{url}</i>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default SearchLink;