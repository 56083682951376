// linksData.ts
import {SiteAltaVista} from "../sites/indexes/sitealtavista";
import {Phonelookup} from "../sites/phonelookup";
import {ReactNode} from "react";
import {DoctorNet} from "../sites/doctornet";
import {CreditScoreLookup} from "../sites/creditscorelookup";
import {PhoneCompany} from "../sites/phonecompany";
import {Decryptor} from "../sites/minigames/decryptor";
import {Conventionpage} from "../sites/cosplay/conventionpage";
import {Myface} from "../sites/socialmedia/myface";
import {EasyMail} from "../sites/services/easymail";

interface LinkData {
    title: string;
    url: string;
    icon: string;
    description: string;
    categories: string[];
    keywords: string[];
    content: ReactNode;
}

const linksData: LinkData[] = [
    {
        title: 'OmniVista',
        url: 'omnivista.com',
        icon: '/sites/omnivista/siteicon.png',
        description: 'Site catalogue, Search engine and more.',
        categories: ['search', 'tools','internet'],
        keywords: ['google', 'search engine', 'search'],
        content: <SiteAltaVista />
    },
    {
        title: 'MyFace',
        url: 'myface.com',
        icon: '/sites/myface/myface.png',
        description: 'The largest social media site to share pictures, find friends and get connected..',
        categories: ['social media'],
        keywords: ['facebook', 'myspace'],
        content: <Myface />
    },
    {
        title: 'Amber Pages',
        url: 'amberpages.com',
        icon: '/sites/amberpages/siteicon.png',
        description: 'Phone and address lookup service.',
        categories: ['telephones','addresses', 'tools','lookup'],
        keywords: ['github', 'git', 'repository'],
        content: <Phonelookup/>
    },
    {
        title: 'DoctorNet',
        url: 'doctornet.com',
        icon: '/sites/docnet/siteicon.png',
        description: 'Medical intranet for doctors, surgeons and dentists.',
        categories: ['medical','intranet'],
        keywords: ['github', 'git', 'repository'],
        content: <DoctorNet/>
    },
    {
        title: 'EasyMail',
        url: 'easymail.com',
        icon: '/sites/easymail/siteicon.png',
        description: 'Easy and 100% free webemail hosting.',
        categories: ['internet','communication'],
        keywords: ['email','hotmail'],
        content: <EasyMail/>
    },
    {
        title: 'CrediCore',
        url: 'credicore.com',
        icon: '/sites/creditcore/siteicon.png',
        description: 'Look up credit scores on potential lenders, tenants and employees.',
        categories: ['finances','lookup'],
        keywords: ['github', 'git', 'repository'],
        content: <CreditScoreLookup/>
    },
    {
        title: 'JoseiCon',
        url: 'joseicon.no',
        icon: '/sites/josei/siteicon.png',
        description: 'Convention for anime, manga, movies and fandom.',
        categories: ['events','conventions'],
        keywords: ['github', 'git', 'repository'],
        content: <Conventionpage/>
    },
    {
        title: 'TeleT',
        url: 'telet.com',
        icon: '/sites/telet/siteicon.png',
        description: 'Your mobile service provider. Check your account details and call history.',
        categories: ['telephones'],
        keywords: ['github', 'git', 'repository'],
        content: <PhoneCompany/>
    },
    {
        title: 'Decrypto',
        url: 'decrypto.net',
        icon: '/sites/decrypto/siteicon.png',
        description: 'Browser based encryption tool to help you easily decrypt specific documents.',
        categories: ['tools','security'],
        keywords: ['github', 'git', 'repository'],
        content: <Decryptor/>
    },
    // Add more links as needed
];
export default linksData;
