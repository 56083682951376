import ServerHandler from "../objects/ServerHandler";
import axios from "axios";
import React, {useMemo, useState} from "react";
import {ColumnDef} from "@tanstack/react-table";
import {Table} from "../interfaces/Table";

type PhonelookupObject = {
    name: string;
    number : string;
}

export function Phonelookup() {

    let items : PhonelookupObject[] = [];
    const [result, setResult] = useState<PhonelookupObject[]>([]);

    function loadQuery(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            querystring: { value: string };
        };

        axios.post(ServerHandler.host()+"/lookup/phone",
            {
                query: target.querystring.value
            }).then(response => {
            items = [];
            for (let item of response.data.people) {
                items.push({
                    name: item.charactername,
                    number : item.phonenumber
                });
            }
            setResult(items);
        }).catch(function(error) {
            console.log(error);
        });
    }


    const cols = useMemo<ColumnDef<PhonelookupObject>[]>(
        () => [
            {
                header: 'Name',
                cell: (row) => row.renderValue(),
                accessorKey: 'name',
                filterable : true
            },
            {
                header: 'Number',
                cell: (row) => row.renderValue(),
                accessorKey: 'number',
                filterable : true
            },
        ],
        []
    );

    return (
        <>
            <div className="text-center">
                <img src="/sites/amberpages/logo.png" className="sitelogo"/>
                <h1>Phone lookup service</h1>
            </div>

            <form className="my-3" onSubmit={loadQuery}>
                <div className="input-group">
                    <input
                        type="text"
                        name="querystring"
                        className="form-control"
                        placeholder="Name of person or part of phone number..."
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                            Search
                        </button>
                    </div>
                </div>
            </form>


            {result.length > 0 &&
                <div className="card mt-3">
                    <div className="card-header">
                        <h1>{result.length} Results</h1>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table data={result} columns={cols} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
